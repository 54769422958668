import React from 'react';
import { Link } from 'react-router-dom';
import Register from '../../pages/Register/Register';
import './Sidebar.css';

const Sidebar = () => {
  const handleLogout = () => {
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('userData');
    window.location.href = '/login'; // Redirect to login page
  };

  return (
    <aside className="dashboard-sidebar">
      <nav>
        <ul>
          <li><Link to="/tasks">Task List</Link></li>
          <li><Link to="/create-task">Create Task</Link></li>
          <li><Link to="/pages/register">Register</Link></li> {/* Link to Registration Form */}
          <li><button onClick={handleLogout} className="logout-button">Logout</button></li>
          {/* Add more navigation links here */}
        </ul>
      </nav>
    </aside>
  );
};

export default Sidebar;
