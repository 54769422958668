import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import MainContent from '../MainContent/MainContent';
import './Dashboard.css';

const Dashboard = () => {
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const isAuthenticated = localStorage.getItem('isAuthenticated');
    if (isAuthenticated !== 'true') {
      navigate('/login');
    } else {
      // Retrieve user data from localStorage
      const storedUserData = localStorage.getItem('userData');
      if (storedUserData) {
        setUserData(JSON.parse(storedUserData));
      }
    }
  }, [navigate]);

  // const handleLogout = () => {
  //   localStorage.removeItem('isAuthenticated');
  //   localStorage.removeItem('userData');
  //   navigate('/login');
  // };

  return (
    <div className="dashboard-container">
      {/* <header className="dashboard-header">
        
        {userData && <div>Welcome, {userData.firstname} {userData.lastname}!</div>}
      </header> */}
      <Header />
      <Sidebar />
      {/* <aside className="dashboard-sidebar">
        <nav>
          <ul>
            <li><Link to="/tasks">Task List</Link></li>
            <li><Link to="/create-task">Create Task</Link></li>
            <li><Link to="/" onClick={handleLogout}>Logout</Link></li>
          </ul>
        </nav>
      </aside> */}
      {/* <main className="dashboard-main">
        <h2>Main Content</h2> */}
        {/* Display more user data or other content here */}
      {/* </main> */}
      <MainContent />
      <Footer />
      {/* <footer className="dashboard-footer">Dashboard Footer</footer> */}
    </div>
  );
};

export default Dashboard;
