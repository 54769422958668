import React from 'react';
// import LogoutButton from '../LogoutButton/LogoutButton';
import './Header.css';

const Header = () => {
  return (
    <header className="header">
      <h1 className="header-title">Dashboard</h1>
      {/* <LogoutButton /> */}
    </header>
  );
};

export default Header;
