import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './components/Dashboard/Dashboard'; // Ensure this path is correct
import Login from './components/Login/Login'; // Ensure this path is correct
import Registration from './components/Registration/Registration';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />}>
          {/* Use relative path for child routes */}
          <Route path="registration" element={<Registration />} />
          {/* Add other nested routes here */}
        </Route>
        {/* Add other routes here */}
      </Routes>
    </Router>
  );
};

export default App;
